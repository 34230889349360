/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const bannerStyles = makeStyles(() => ({
  bannerSec: {
    paddingTop: 79,
    paddingBottom: 85,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  title: {
    wordBreak: 'keep-all',
  },
}));

export const departmentsStyles = makeStyles(() => ({
  departmentsSec: {
    paddingBottom: 100,
  },
  title: {
    textAlign: 'center',
    marginBottom: 70,
  },
  departmentTitle: {
    marginTop: 10,
    marginBottom: 15,
    minHeight: 80,
  },
}));

export const itemsStyles = makeStyles(() => ({
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    margin: '8px 0 !important',
    padding: '0',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    height: 78,
  },
  subtitle: {
    paddingTop: 8,
    paddingBottom: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  date: {
    color: '#404042',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: 16,
  },
  grid: {
    justifyContent: 'space-evenly',
  },
  card: {
    overflow: 'hidden',
    background: 'transparent',
    position: 'relative',
  },
  cardImg: {
    aspectRatio: 360 / 207,
    width: 360,
    maxWidth: 360,
    height: 207,
    objectFit: 'cover',
    objectPosition: 'center top',
    borderRadius: 16,
  },
  tags: {
    marginBottom: 54,
    marginTop: 80,
  },
  tag: {
    padding: '6px 24px',
    marginRight: 8,
  },
  itemTag: {
    fontSize: '12px !important',
    paddingTop: 16,
  },
  activeTag: {
    backgroundColor: '#EBE6E2!important',
  },
  loadMoreBtn: {
    marginTop: '64px !important',
    width: '263px',
    fontSize: '14px',
    lineHeight: '16px',
    height: '50px',
    background: 'transparent',
  },
  rightArrow: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    width: 16,
    height: 27,
  },
  newsContent: {
    height: 110,
    overflow: 'hidden',
  },
}));

export const previewStyles = makeStyles(() => ({
  previewSec: {
    paddingTop: 100,
    paddingBottom: 67,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 70,
    marginTop: 32,
  },
}));

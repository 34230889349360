/* eslint-disable jsx-a11y/media-has-caption */
// @flow
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import get from 'lodash.get';
import uniqby from 'lodash.uniqby';
import { graphql } from 'gatsby';
import { Container } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { SITE_METADATA } from '../constants';

import * as MediaCenterActions from '../redux/mediaCenter/actions';
import BoxIntro from '../components/BoxIntro';
import Layout from '../components/Layout/Layout';
import styles from '../styles/pages/index.module.scss';
import { TopBanner2, TopBannerText } from '../components';
import { PreviewSec, ItemsSec } from '../components/Home/News';
import PreviewJob from '../components/Home/PreviewJobs/PreviewJob';
import { NavigationSec } from '../components/mediaCenterSections/pressKit';

import banner from '../images/home-hero.png';
import mobileBanner from '../images/home-hero-mobile.png';

export const query = graphql`
  {
    contentfulPageMediaCenterRecruitment(
      contentful_id: { ne: "5mCqMKbm5Zmj4OU6uhg9np" }
    ) {
      pageSubtitle
      pageTitle
      firstSectionPrimaryText
      firstSectionSecondaryText
      firstSectionPrimaryImage {
        file {
          url
        }
      }
      firstSectionSecondaryaryImage {
        file {
          url
        }
      }
      firstSectionTertiaryImage1 {
        file {
          url
        }
      }
      firstSectionTertiaryImage2 {
        file {
          url
        }
      }
      secondSectionSubtitle
      secondSectionTitle
      thirdSectionSubtitle
      thirdSectionTitle
      thirdSectionTags {
        title
        contentful_id
      }
    }
    allGreenhouseDepartment(
      filter: {
        childrenGreenhouseJobPost: { elemMatch: { active: { eq: true } } }
      }
    ) {
      nodes {
        id
        name
      }
    }
    allGreenhouseJobPost {
      nodes {
        id
        greenhouseId
        title
        created_at
        location {
          name
        }
        parent {
          ... on GreenhouseDepartment {
            id
            name
          }
        }
      }
    }
    allContentfulComponentArticle(
      filter: {
        blogSection: { contentful_id: { eq: "1w915a0HhAJPBs9eqSjl19" } }
      }
    ) {
      edges {
        node {
          contentful_id
          date
          blogSection {
            urlKey
          }
          previewImage {
            file {
              url
            }
          }
          title
          tags {
            title
            contentful_id
          }
        }
      }
    }
    site {
      siteMetadata {
        ogImage
        title
        twitterImage
        siteUrl
      }
    }
  }
`;

const STICKY_POSITION = 275;

const IndexPage = ({ data, location }) => {
  const tags = decodeURIComponent(location.hash.replace('#', ''));

  const dispatch = useDispatch();

  const pressPage = useSelector((state) => state.mediaCenter.pressPage);

  const { data: pressArticles } = useSelector(
    (state) => state.mediaCenter.pressArticles
  );

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getPressPageAsync());
    dispatch(MediaCenterActions.getPressArticlesAsync());
  }, [dispatch]);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navBlocks = [
    {
      id: 'ニュースルーム',
      title: 'ニュースルーム',
    },
    {
      id: 'サービス',
      title: 'サービス',
    },
    {
      id: 'Career',
      title: '採用情報',
    },
  ];

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.top.title,
        description: SITE_METADATA.top.description,
      }}
    >
      <TopBanner2 bgImg={banner} mobileBgImg={mobileBanner}>
        <TopBannerText className={styles.titleBanner}>
          もう、“夢後進国”とは言わせない。
        </TopBannerText>
        <OutboundLink href="/dream">
          <button className={cx('btn charcoal', styles.bannerDetailBtn)}>
            もっと詳しく
          </button>
        </OutboundLink>
      </TopBanner2>
      <NavigationSec
        blocks={navBlocks}
        location={location}
        className={cx(styles.navigator, {
          [styles.navigatorSticky]: scrollPosition >= STICKY_POSITION,
        })}
        full={false}
        tabStyle={{
          fontSize: '14px',
          lineHeight: '16px',
        }}
      />
      <div id="ニュースルーム">
        <PreviewSec
          title={get(pressPage, 'pageTitle')}
          subtitle={get(pressPage, 'pageSubtitle')}
          card={{
            ...get(pressPage, 'primaryArticle.fields', {}),
            imgUrl: get(
              pressPage,
              'primaryArticle.fields.previewImage.fields.file.url'
            ),
            url: `/news/article/${get(pressPage, 'primaryArticle.sys.id')}`,
            tags: get(pressPage, 'primaryArticle.fields.tags', []).map(
              (item) => item.fields.title
            ),
            btnText: 'ニュースを読む',
          }}
        />
      </div>

      <div className={styles.previewNewsItems}>
        <ItemsSec
          previewMode
          title={get(pressPage, 'secondSectionTitle')}
          tags={get(pressPage, 'tags', [])}
          items={pressArticles.slice(0, 3)}
          selectedTag={tags}
        />
      </div>
      <BoxIntro
        anchorId="サービス"
        title="あと払いサービス「ペイディ」"
        image={{
          url: require('../images/home-intro-4.png'),
          alt: 'paidy app',
        }}
        imageStyle={{ marginLeft: '-20px' }}
        wrapperClassName={styles.serviceContentWrapper}
        description={
          <div className={styles.serviceContent}>
            ペイディが提供するのは、スマホだけで欲しいものを今すぐ購入できて、お支払いは「まとめてあとから」行っていただける「あと払い（Buy
            Now Pay Later）」サービス。
            <br />
            <br />
            シンプルなUX、翌月一括あと払いまたは分割手数料無料*
            の3・6・12回あと払いで支払いが可能な利便性、スマホで賢くお金の管理ができる点などから自分らしく使える「自由なあと払い」として多くの方にご利用いただいています。
            <br />
            <br />
            「夢に自信を持ち、心に余裕を持てる世界」を作ることを目指して。お客様への信頼をもとに、どなたでも安心してお買い物を楽しめる環境を整えることが、ペイディの大事な使命です。
            <p className={styles.notes}>*口座振替・銀行振込のみ無料</p>
          </div>
        }
        link={{
          to: 'https://paidy.com/',
          children: 'サービスページへ',
        }}
        secondLink={{
          to: 'https://paidy.com/merchant/',
          children: '導入を検討中の方はこちら',
        }}
        maxWidth={1128}
      />
      <div className={styles.previewJob} id="Career">
        <Container maxWidth="lg">
          <PreviewJob
            title={get(
              data,
              'contentfulPageMediaCenterRecruitment.secondSectionTitle'
            )}
            subtitle={get(
              data,
              'contentfulPageMediaCenterRecruitment.secondSectionSubtitle'
            )}
            tags={uniqby(get(data, 'allGreenhouseDepartment.nodes', []), 'id')}
            items={get(data, 'allGreenhouseJobPost.nodes', [])}
            selectedTag={tags}
            loadBy={3}
          />
        </Container>
      </div>
    </Layout>
  );
};

// $FlowFixMe
export default IndexPage;

import { Link } from 'gatsby';
import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import BoxIndex from '../BoxIndex/BoxIndex';
import styles from './styles.module.scss';

const BoxIntro = ({
  title,
  image,
  description,
  showAppDownload,
  link,
  secondLink,
  notes,
  imageStyle,
  maxWidth = 1280,
  ...props
}) => (
  <BoxIndex title={title} sectionStyle={{ maxWidth }} {...props}>
    <div className={styles.content}>
      <img
        src={image.url}
        alt={image.alt}
        style={imageStyle}
        width={625}
        loading="lazy"
      />
      <div className={styles.details}>
        <p className={styles.description}>{description}</p>
        {showAppDownload && (
          <div className={styles.appDownload}>
            <div className={styles.qrWrapper}>
              <img
                className={styles.qrCode}
                src={require('../../images/qr-code-home.png')}
                alt="QR Code"
              />
            </div>
            <div className={styles.downloadContent}>
              <p>
                ペイディアプリでお買い物を
                <br />
                もっと便利に。もっと楽しく。
              </p>
              <div className={styles.badges}>
                <OutboundLink
                  className=""
                  href="https://apps.apple.com/jp/app/paidy/id1220373112"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={require('../../images/badge-app-store.png')}
                    alt="Apple App Store - Paidy"
                    width="108"
                    height="32"
                    loading="lazy"
                  />
                </OutboundLink>
                <OutboundLink
                  className=""
                  href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={require('../../images/badge-play-store.png')}
                    alt="Google Play Store - Paidy"
                    width="108"
                    height="32"
                    loading="lazy"
                  />
                </OutboundLink>
              </div>
            </div>
          </div>
        )}
        {link && <Link className={cx('btn charcoal', styles.cta)} {...link} />}
        {secondLink && (
          <Link className={cx('btn', styles.secondLink)} {...secondLink} />
        )}
        {notes && <div className={styles.notes}>{notes}</div>}
      </div>
    </div>
  </BoxIndex>
);

export default BoxIntro;

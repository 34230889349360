import { makeStyles } from '@material-ui/core/styles';

const itemsStyles = makeStyles(() => ({
  section: {
    padding: `0 0 112px`,
  },
  '@media (max-width: 1024px)': {
    section: {
      padding: `0 8px 64px`,
    },
    loadMoreBtn: {
      width: '343px !important',
    },
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    margin: '8px 0 !important',
    padding: '0 16px',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  },
  subtitle: {
    paddingTop: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  card: {
    height: 323,
    borderRadius: 16,
    overflow: 'hidden',
    background: '#FAFAFA',
    position: 'relative',
  },
  cardImg: {
    width: '100%',
    height: 165,
    objectFit: 'cover',
    objectPosition: 'center top',
  },
  tags: {
    marginBottom: 64,
    marginTop: 64,
  },
  tag: {
    marginRight: 16,
  },
  itemTag: {
    fontSize: '12px !important',
    paddingTop: 16,
    paddingRight: 16,
    paddingLeft: 16,
  },
  activeTag: {
    backgroundColor: '#EBE6E2!important',
  },
  loadMoreBtn: {
    marginTop: '64px !important',
    width: '263px',
    fontSize: '14px',
    lineHeight: '16px',
    height: '50px',
    background: 'transparent',
  },
  rightArrow: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    width: 16,
    height: 27,
  },
  newsContent: {
    height: 110,
    overflow: 'hidden',
  },
}));

export default itemsStyles;

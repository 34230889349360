// @flow
import React from 'react';
import cx from 'classnames';
import { Container, Grid } from '@material-ui/core';
import get from 'lodash.get';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import moment from 'moment';

import typography from '../../../../styles/typography.module.scss';

import styles from '../NewsComponents.module.scss';

const PreviewSec = ({ card }) => {
  return (
    <div className={styles.previewBox}>
      <Container className={styles.container}>
        <Grid
          container
          className={styles.gridContainer}
          justify="space-between"
        >
          <Grid item xs={12} md={6} className={styles.info}>
            <div className={cx(styles.previewTag, typography.textSmGray)}>
              {get(card, 'tags', []).join(', ')}
            </div>
            {card.title && <h3>{card.title}</h3>}
            <p className={styles.subtitle}>{card.subtitle}</p>
            <p className={styles.date}>
              {moment(card.date).format('YYYY/MM/DD')}
            </p>
            <div className={styles.showMoreBtn}>
              <OutboundLink className="btn charcoal" href={card.url}>
                ニュースを読む
              </OutboundLink>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <OutboundLink className={styles.imgWrapper} href={card.url}>
              <img
                alt="preview"
                src={get(card, 'previewImage.fields.file.url')}
              />
            </OutboundLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PreviewSec;

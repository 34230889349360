// @flow
//Core
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Box, Chip, Button, Container } from '@material-ui/core';
import classNames from 'classnames';

import get from 'lodash.get';
import { Link } from 'gatsby';
//Local
import { itemsStyles } from '../styles';
import { filterCardsForPublish } from '../../utils';
import { PaginationColor } from '../../..';
import ColumnPostItem from './ColumnPostItem';

const ItemsSec = ({
  tags,
  items: initialItems,
  loadBy = 9,
  selectedTag,
  previewMode,
}) => {
  const styles = itemsStyles({});
  const [items, setItems] = useState(initialItems);

  const [filterBy, setFilterBy] = useState(selectedTag);

  const [page, setPage] = useState(0);
  const [filteredItems, setFilteredItem] = useState(
    filterCardsForPublish(items)
  );

  const handleTagClick = (id, tagTitle) => {
    setFilterBy(id);
    window.location.hash = tagTitle;
  };

  useEffect(() => {
    const from = page * loadBy;
    const to = from + loadBy;

    setFilteredItem(filterCardsForPublish(items).slice(from, to));
  }, [page, items]);

  const filterItemsByTag = useCallback(
    (data) =>
      data.filter((item) =>
        filterBy
          ? get(item, 'fields.tags', []).find((tag) => tag.sys.id === filterBy)
          : initialItems
      ),
    [filterBy, initialItems]
  );

  useEffect(() => {
    setItems(filterItemsByTag(initialItems));
    setPage(0);
  }, [filterBy, initialItems, filterItemsByTag]);

  useEffect(() => {
    const tag = tags.find((t) => t.fields.title === selectedTag);

    if (!tag) {
      setFilterBy(null);
    } else if (tag.sys.id !== filterBy) {
      setFilterBy(tag.sys.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, tags, filterBy]);

  const tagsJSX = [
    {
      fields: { title: 'すべての記事' },
      sys: { id: null },
    },
    ...tags,
  ].map((item, index) => (
    <Chip
      label={item.fields.title}
      onClick={() => handleTagClick(item.sys.id, item.fields.title)}
      key={index}
      clickable
      color="secondary"
      className={classNames(styles.tag, {
        [styles.activeTag]: item.sys.id === filterBy,
      })}
    />
  ));

  const itemsJSX = filteredItems.map((item, index) => (
    <ColumnPostItem
      itemUrl={`/news/article/${get(item, 'id')}`}
      title={get(item, 'title')}
      subtitle={get(item, 'subtitle')}
      tags={get(item, 'tags', [])
        .map((tag) => get(tag, 'fields.title'))
        .join(', ')}
      previewImage={get(item, 'previewImage.fields.file.url')}
      index={index}
    />
  ));

  return (
    <section className={styles.section}>
      <Container maxWidth="lg">
        {!previewMode && <Box className={styles.tags}>{tagsJSX}</Box>}
        <Grid container zeroMinWidth spacing={3} justify="space-evenly">
          {itemsJSX}
        </Grid>

        {previewMode ? (
          <Box textAlign="center">
            <Link to="/news/">
              <Button
                className={styles.loadMoreBtn}
                variant="outlined"
                color="primary"
              >
                もっと見る
              </Button>
            </Link>
          </Box>
        ) : (
          <PaginationColor
            pageCount={Math.ceil(initialItems?.length / loadBy)}
            page={page}
            gotoPage={(selected) => setPage(selected)}
          />
        )}
      </Container>
    </section>
  );
};

ItemsSec.defaultProps = {
  previewMode: false,
  items: [],
  handleCustomLoadMore: undefined,
  isLoaded: undefined,
  loadBy: 9,
  selectedTag: null,
  centeredCardBackground: true,
};

export default ItemsSec;

// @flow
//Core
import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { Link } from 'gatsby';
import cx from 'classnames';
import get from 'lodash.get';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { itemsStyles } from '../styles';
import JobPreviewImg from '../../../images/jobPreview.jpg';
import newsComponentsStyles from '../News/NewsComponents.module.scss';
import typography from '../../../styles/typography.module.scss';
import previewCardStyle from './previewStyle';

const JobsSec = ({
  title,
  tags,
  items: initialItems,
  loadBy = 6,
  handleCustomLoadMore,
  selectedTag,
}) => {
  const styles = itemsStyles();
  const previewStyle = previewCardStyle();

  const [items, setItems] = useState(initialItems);
  const [filterBy, setFilterBy] = useState(null);
  const [loadedCount] = useState(loadBy);

  const filterItemsByTag = (data) =>
    data.filter((item) =>
      filterBy ? get(item, 'parent.id', []) === filterBy : initialItems
    );

  const filterLoadedItems = (data) => data.slice(0, loadedCount);

  useEffect(() => {
    setItems(
      handleCustomLoadMore
        ? filterItemsByTag(initialItems)
        : filterLoadedItems(filterItemsByTag(initialItems))
    );
  }, [initialItems, filterBy, loadedCount]);

  useEffect(() => {
    const tag = tags.find((t) => t.name === selectedTag);

    if (!tag) {
      setFilterBy(null);
    } else if (tag.id !== filterBy) {
      setFilterBy(tag.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, tags]);

  const itemsJSX = items.map((item, index) => (
    <Grid
      xs={12}
      md={4}
      key={index}
      item
      className={newsComponentsStyles.newsCard}
      zeroMinWidth
    >
      <OutboundLink
        href={`/media_center/work_life/recruitment/job/${get(
          item,
          'greenhouseId'
        )}`}
      >
        <div className={previewStyle.card}>
          <img src={JobPreviewImg} alt="" className={previewStyle.cardImg} />
          {tags && (
            <div className={cx(previewStyle.itemTag, typography.textSmDark)}>
              {[item.parent.name]}
            </div>
          )}
          <div className={previewStyle.newsContent}>
            <h2 className={cx(previewStyle.title, typography.titleH7Dark)}>
              {get(item, 'title')}
            </h2>
          </div>
        </div>
      </OutboundLink>
    </Grid>
  ));

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <Typography className={styles.title} variant="h2">
          {title}
        </Typography>
        <Grid container spacing={3} justify="space-evenly">
          {itemsJSX}
        </Grid>
        <Box textAlign="center">
          <Link to="/recruit">
            <Button
              className={styles.loadMoreBtn}
              variant="outlined"
              color="primary"
            >
              もっと詳しく
            </Button>
          </Link>
        </Box>
      </div>
    </section>
  );
};

JobsSec.defaultProps = {
  items: [],
  handleCustomLoadMore: undefined,
  isLoaded: undefined,
  loadBy: 6,
  selectedTag: '',
};

export default JobsSec;
